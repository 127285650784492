export function initGlossaryAccordion() {
    const glossaryFilterButtons = document.querySelectorAll(".dm-glossary .dm-tag");

    if (glossaryFilterButtons.length > 0) {
        
        const glossaryAccordions = document.querySelectorAll(".dm-accordion");

        glossaryFilterButtons.forEach((button) => {
            const accordionId = button.dataset.filter;

            button.addEventListener("click", () => {
                glossaryFilterButtons.forEach((btn) => btn.classList.remove("active"));

                glossaryAccordions.forEach((accordion) => accordion.style.display = "none");

                const targetAccordion = Array.from(glossaryAccordions).find((accordion) => accordion.dataset.accordionId === accordionId);

                if (targetAccordion) {
                    targetAccordion.style.display = "block";
                }

                button.classList.add("active");
            });
        });
    }
}