import { toggleMobileMenu } from "./modules/main-menu.mjs";
import { initAccordion } from "./modules/accordion.mjs";
import { initCasesSlider } from "./modules/our-cases-slider.mjs";
import { initInspirationSlider } from "./modules/inspiration-slider.mjs";
import { initSocialSlider } from "./modules/social-slider.mjs";
import { initPartnerSlider } from "./modules/partners-slider.mjs";
import { initClientQuotesSlider } from "./modules/client-quotes-slider.mjs";
import { initBlogWallSlider } from "./modules/blog-wall-slider.mjs";
import { initImageSlider } from "./modules/image-slider.mjs";
import { initTeaserCardsSlider } from "./modules/teaser-card-slider.mjs";
import { initBlogArticleSlider } from "./modules/blog-article-slider.mjs";
import { formAction } from "./modules/contact-form.mjs";
import { handleVideoModule } from "./modules/video-module.mjs";
import { filterContentDelivery } from "./modules/cases-and-blog-filtering.mjs";
import { jobFilter } from "./modules/job-filter.mjs";
import { videoSrcSwitch } from "./modules/video-src-switch.mjs";
import { initProductSlider } from "./modules/product-slider.mjs";
import { initGlossaryAccordion } from "./modules/glossary.mjs";
import { initAIChat } from "./modules/ai-chat.mjs";
import { snow } from "./modules/snow.mjs";

toggleMobileMenu();
initAccordion();
initCasesSlider();
initInspirationSlider();
initSocialSlider();
initPartnerSlider();
initClientQuotesSlider();
initBlogWallSlider();
initImageSlider();
initTeaserCardsSlider();
initBlogArticleSlider();
formAction();
handleVideoModule();
filterContentDelivery();
jobFilter();
videoSrcSwitch();
initProductSlider();
initGlossaryAccordion();
initAIChat();
snow();
