export function initAIChat() {
    // Global variables
    let sessionInfo = null;
    let peerConnection = null;
    let pendingFormRequest = false;
    let pendingStopAvatarRequest = false;

    const dmAIChat = document.querySelector(".dm-ai-chat");

    if(dmAIChat) {
        // DOM Elements
        const form = document.querySelector(".dm-ai-form");
        const errorContainer = form.closest(".dm-ai-chat__form").querySelector(".chat-error");
        const mediaElement = form.closest(".dm-ai-chat").querySelector(".js-avatar-video");
        const taskInput = form.querySelector(".js-task-input");
        const apiUrl = form.getAttribute("data-url");
        const submitButton = form.querySelector(".js-talk-button");
        const loadingSpinner = form.closest(".dm-ai-chat__wrapper").querySelector(".loader");
        const audio = form.closest(".dm-hero--ai").querySelector("audio");
        const stopAvatarButton = form.closest(".dm-ai-chat").querySelector(".js-stop-avatar");
        const greetingVideo = dmAIChat.querySelector(".js-greeting-video");
        let disableFormTimeout;

        // Get video with unique identifier
        const params = new URLSearchParams(window.location.search);
        const clientHash = params?.get("ch")?.replace(/(^[^\wäöüß-]+|[^\wäöüß-]+$|\b[^\wäöüß-]+\b)/g, ' ').trim(); //.replace(/(^[^\wäöüß]+|[^\wäöüß]+$|\b[^\wäöüß]+\b)/g, ' ')
        const hashIdentifier = clientHash || "";
        
        async function createNewSession() {
            
            try {
                // Initialize streaming session
                const response = await fetch(`${apiUrl}/api/ai/heygen/new-session/`);
                
                if(!response.ok) {
                    throw new Error("Something went wrong when getting session. Try again later.");
                }

                const data = await response.json();

                sessionInfo = data;
                
                // Setup WebRTC peer connection
                const { sdp: serverSdp, ice_servers2: iceServers } = sessionInfo;
                
                // Initialize with ICE servers for NAT traversal
                peerConnection = new RTCPeerConnection({ iceServers });

                // Configure media handling
                peerConnection.ontrack = (event) => {
                    // Attach incoming media stream to video element
                    if (event.track.kind === 'audio' || event.track.kind === 'video') {
                        mediaElement.srcObject = event.streams[0];
                    }
                };

                // Set server's SDP offer
                await peerConnection.setRemoteDescription(new RTCSessionDescription({type: 'offer', sdp: serverSdp}));
            } catch (error) {
                console.log(error)
                loadingSpinner.classList.remove("d-block");
            }
        }

        // Send text to avatar
        async function sendText(e) {
            e.preventDefault();

            if (pendingFormRequest) return;

            if(errorContainer.classList.contains("d-block")) {
                errorContainer.classList.remove("d-block");
            }

            if (!sessionInfo) {
                errorContainer.classList.add("d-block");
                return;
            }

            let inputText = "";

            inputText = taskInput.value.replace(/(^[^\wäöüß]+|[^\wäöüß]+$|\b[^\wäöüß]+\b)/g, ' ').trim();

            if(inputText === "") {
                return;
            }
            const messageContainer = form.closest(".dm-ai-chat").querySelector(".chat-message");

            if(messageContainer.classList.contains("d-block")) {
                messageContainer.classList.remove("d-block")
            }

            messageContainer.querySelector("p").innerHTML = inputText;
            messageContainer.classList.add("d-block");
            
            submitButton.setAttribute('disabled', 'true');
            pendingFormRequest = true;
            loadingSpinner.classList.add("d-block");

            try {
                const response = await fetch(`${apiUrl}/api/ai/heygen/chat-ai/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        session_id: sessionInfo.session_id,
                        text: inputText,
                        identifier: hashIdentifier
                    }),
                });
                
                taskInput.value = "";
                const textResponse = await response.json();

                if (!response.ok) {
                    throw new Error(`${textResponse.detail}. Try again later.` || `HTTP error! status: ${response.status}`);
                }

                // Send text to avatar to speak
                const heygenResponse = await fetch(`${apiUrl}/api/ai/heygen/send-task/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        session_id: sessionInfo.session_id,
                        text: textResponse.response,
                    }),
                });

                if(stopAvatarButton.classList.contains("d-none")) {
                    stopAvatarButton.classList.remove("d-none");
                    stopAvatarButton.classList.add("fade-in");
                }

                stopAvatarButton.removeAttribute("disabled");
                loadingSpinner.classList.remove("d-block");
                const heygenFetchResponse = await heygenResponse.json();

                if (!heygenResponse.ok) {
                    throw new Error(`${heygenFetchResponse.detail}. Try again later.` || `HTTP error! status: ${heygenResponse.status}`);
                }

                const messageDuration = heygenFetchResponse.response?.data?.duration_ms || 0;

                disableFormTimeout = setTimeout(() => {
                    submitButton.removeAttribute("disabled");
                    stopAvatarButton.setAttribute('disabled', 'true');
                    pendingFormRequest = false;

                    if(form.classList.contains("d-none")) {
                        form.classList.remove("d-none");
                        form.classList.add("fade-in");
                    }

                    clearTimeout(disableFormTimeout);
                }, messageDuration);
                
                
            } catch (error) {
                console.log(error);
                pendingFormRequest = false;
                loadingSpinner.classList.remove("d-block");

                if(!errorContainer.classList.contains("d-block")) {
                    errorContainer.classList.add("d-block");
                }
            }
        }

        // Handle ICE candidates
        async function handleICE(session_id, candidateObj) {
            const { candidate, sdpMLineIndex, sdpMid, usernameFragment } = candidateObj;
            
            try {
                const response = await fetch(`${apiUrl}/api/ai/heygen/submit-ice/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        session_id,
                        candidate,
                        sdp_mline_index: sdpMLineIndex,
                        sdp_mid: sdpMid,
                        username_fragment: usernameFragment
                    }),
                });
                
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                console.error('ICE candidate error:', error);
            } 
            
        }

        // Start streaming session
        async function startStreamingSession(e) {
            // Create and set local description
            loadingSpinner.classList.add("d-block");
            await createNewSession();

            const localDescription = await peerConnection.createAnswer();
            const { sdp, type } = localDescription;
            
            await peerConnection.setLocalDescription(localDescription);

            // Handle ICE candidates
            peerConnection.onicecandidate = ({ candidate }) => {
                if (candidate) {
                    handleICE(sessionInfo.session_id, candidate.toJSON());
                }
            };

            // Monitor connection state changes
            peerConnection.oniceconnectionstatechange = () => {
                console.log(`ICE Connection State: ${peerConnection.iceConnectionState}`);
                if(peerConnection.iceConnectionState === "disconnected") {
                    closeSession();
                } else if(peerConnection.iceConnectionState === "connected") {
                    window.dataLayer.push({
                        "event": "select_content",
                        "event_id": 1.5
                    });
                }
                
            };

            // Start streaming
            try {
                const startResponse = await fetch(`${apiUrl}/api/ai/heygen/start-session/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        session_id: sessionInfo.session_id,
                        sdp_data: sdp,
                        sdp_type: type
                    }),
                });
                
                const data = await startResponse.json();
                
                if(!startResponse.ok) {
                    throw new Error(`${data.detail}. Try again later.` || "Something went wrong when getting starting streaming");
                }

                if(form.classList.contains("d-none")) {
                    form.classList.remove("d-none");
                    form.classList.add("fade-in");
                    submitButton.removeAttribute("disabled");
                }

            } catch (error) {
                console.log(error)
                if(!errorContainer.classList.contains("d-block")) {
                    errorContainer.classList.add("d-block");
                }
            } finally {
                loadingSpinner.classList.remove("d-block");
            }
        }

        async function closeSession() {
            if (!sessionInfo) {
                return;
            }

            try {
                await fetch(`${apiUrl}/api/ai/heygen/close-session/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        session_id: sessionInfo.session_id,
                    }),
                });
                
                if (peerConnection) {
                    peerConnection.close();
                    peerConnection = null;
                }
                
                sessionInfo = null;
                mediaElement.srcObject = null;
                firstTextFormSubmit = true;
            } catch (error) {
                console.log(error)
            } finally {
                loadingSpinner.classList.remove("d-block");
                submitButton.removeAttribute('disabled');

                form.classList.add("d-none");
                stopAvatarButton.classList.remove("fade-in");
                stopAvatarButton.classList.add("d-none");
                form.closest(".dm-ai-chat").querySelector(".chat-message p").innerHTML = "";
                form.closest(".dm-ai-chat").querySelector(".chat-message").classList.remove("d-block");

                // Show starting video again
                dmAIChat.querySelector(".dm-ai-chat__play-overlay").classList.remove("d-none");
                greetingVideo.classList.remove("d-none");
                greetingVideo.currentTime = 0;

                const hideOverlayTimeout = setTimeout(() => {
                    dmAIChat.querySelector(".dm-ai-chat__play-overlay").classList.remove("hide");
                    greetingVideo.classList.remove("hide");
                    clearTimeout(hideOverlayTimeout);
                }, 300);
            }   
        }

        async function stopAvatar() {

            if(greetingVideo.currentTime > 0 && !greetingVideo.paused) {
                greetingVideo.pause();
                greetingVideo.classList.add("hide");

                const hideVideoTimeout = setTimeout(() => {
                    greetingVideo.classList.add("d-none");
                    clearTimeout(hideVideoTimeout);
                }, 300);

                stopAvatarButton.classList.remove("fade-in");
                stopAvatarButton.setAttribute("disabled", "true");
                stopAvatarButton.classList.add("d-none");

                window.dataLayer.push({
                    "event": "select_content",
                    "event_id": 1.6
                });

                await startStreamingSession();
                return;
            }

            if (pendingStopAvatarRequest) return;
            pendingStopAvatarRequest = true;

            clearTimeout(disableFormTimeout);
            loadingSpinner.classList.add("d-block");

            try {
                const response = await fetch(`${apiUrl}/api/ai/heygen/interrupt-task/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        session_id: sessionInfo.session_id,
                    }),
                });

                const stopTaskResponse = await response.json();

                if (!response.ok) {
                    throw new Error(`${stopTaskResponse.detail}` || `Looks like something went wrong with request`);
                }

                submitButton.removeAttribute("disabled");

                window.dataLayer.push({
                    "event": "select_content",
                    "event_id": 1.6
                });

            } catch (error) {
                console.log(error);
                if(!errorContainer.classList.contains("d-block")) {
                    errorContainer.classList.add("d-block");
                }
                
            } finally {
                pendingFormRequest = false;
                pendingStopAvatarRequest = false;
                stopAvatarButton.setAttribute('disabled', 'true');
                loadingSpinner.classList.remove("d-block");
                if(form.classList.contains("d-none")) {
                    form.classList.remove("d-none");
                    form.classList.add("fade-in");
                }
            }
        }
            
        form.addEventListener("submit", sendText);
        stopAvatarButton.addEventListener("click", stopAvatar);

        async function fetchVideoLink() {

            if(hashIdentifier !== "") {
                try {
                    const getVideoLink = await fetch(`${apiUrl}/api/ai/heygen/chat-ai/get-link/`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            identifier: hashIdentifier
                        }),
                    });
    
                    if(!getVideoLink.ok) {
                        throw new Error(`Could not get video link`);
                    }
    
                    const getVideoLinkResponse = await getVideoLink.json();
    
                    if(getVideoLinkResponse.link_url) {
                        greetingVideo.src = getVideoLinkResponse.link_url;
                    }

                } catch (error) {
                    console.log(error);
                }
            }

        }
 
        fetchVideoLink();
        
        dmAIChat.querySelector(".dm-ai-chat__play-overlay").addEventListener("click", () => {
            if(audio) {
                audio.volume = 0.06;
                audio.play();
            }
            dmAIChat.querySelector(".js-greeting-video").play();
            window.dataLayer.push({
                "event": "select_content",
                "event_id": 1.7
            });
        });

        greetingVideo.addEventListener("playing", () => {
            dmAIChat.querySelector(".dm-ai-chat__play-overlay").classList.add("hide");

            const hideOverlayTimeout = setTimeout(() => {
                dmAIChat.querySelector(".dm-ai-chat__play-overlay").classList.add("d-none");
                clearTimeout(hideOverlayTimeout);
            }, 300);

            stopAvatarButton.removeAttribute("disabled");
            stopAvatarButton.classList.remove("d-none");
            stopAvatarButton.classList.add("fade-in");

        });

        greetingVideo.addEventListener("ended", () => {
            greetingVideo.classList.add("hide");

            const hideVideoTimeout = setTimeout(() => {
                greetingVideo.classList.add("d-none");
                clearTimeout(hideVideoTimeout);
            }, 300);

            stopAvatarButton.classList.remove("fade-in");
            stopAvatarButton.setAttribute("disabled", "true");
            stopAvatarButton.classList.add("d-none");
            startStreamingSession();
        });

        greetingVideo.addEventListener("error", () => {
            const defaultVideoSrc = greetingVideo.getAttribute("data-default-video");
            greetingVideo.src = defaultVideoSrc;
            // greetingVideo.play();
        });
    }
}